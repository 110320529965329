/*----------------------------------------*/
/*  09. Brand
/*----------------------------------------*/
.brand{
    &-slider{
        .swiper{
            &-wrapper{
                align-items: center;
            }
        }
    }
    &-item{
        display: flex;
        justify-content: center;
    }
}