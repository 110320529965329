/*----------------------------------------*/
/*  16. Footer
/*----------------------------------------*/
.widget{
    &-title{
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        position: relative;
        padding-bottom: 5px;
        &:before{
            background-color: $primary;
            height: 2px;
            width: 40px;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    &-item{
        li,
        a,
        p{
            font-size: 14px;
            line-height: 26px;
        }
        .short-desc{
            max-width: 280px;
        }
    }
    &-contact-info{
        ul{
            li{
                &:not(:last-child){
                    padding-bottom: 5px;
                }
                i{
                    vertical-align: middle;
                    margin-right: 5px;
                    font-size: 24px;
                }
            }
        }
    }
    &-list{
        &-item{
            > li{
                &:not(:last-child){
                    padding-bottom: 10px;
                }
                a{
                    text-transform: uppercase;
                }
            }
        }
    }
    &-form{
        .input-field{
            background-color: #f0ece5;
            color: #a7a7a7;
            font-size: 12px;
            border: 0;
            width: 100%;
            height: 50px;
            padding-left: 15px;
            padding-right: 15px;
        }
        .btn{
            &-primary{
                &:focus{
                    color: $white;
                }
            }
        }
    }
}

/* ---Copyright--- */
.copyright{
    text-align: center;
    &-text{
        text-transform: capitalize;
        font-size: 14px;
        a{
            &:hover{
                color: $white;
            }
        }
    }
}